<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="isAppAdmin(this.$store.state.user._id)">
    <v-card-text>
      <v-autocomplete
        v-model="selectedManagedAcademy"
        :items="academies"
        label="Select An Academy" 
        :loading="loadingManagedAcademies"
        clearable
        dense outlined
      ></v-autocomplete>
      <v-switch v-model="uploadMode" label="Upload Multiple Members"></v-switch>
      <v-switch v-if="uploadMode" v-model="uploadModeAlumni" label="Upload Alumni"></v-switch>
      <v-card-actions v-if="uploadMode">
        <v-btn block outlined elevation="0" small color="primary" outined @click="getUploadstemplate()">
          Get Template
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <div class="text-subtitle-1 mb-2">Fill parameters</div>
        <v-text-field v-if="!uploadMode" dense outlined v-model="user.fName" label="First Name"></v-text-field>
        <v-text-field v-if="!uploadMode" dense outlined v-model="user.lName" label="Last Name"></v-text-field>
        <v-text-field v-if="!uploadMode" dense outlined v-model="user.email" label="Email"></v-text-field>
        <v-text-field dense outlined v-model="user.password" label="Password"></v-text-field>
        <BeltsSelect
          label="Select Belt"
          v-model="user.belt"
          v-if="!uploadMode" 
        />
        <CountriesSelect
          class="mb-1"
          label="Select Country"
          v-model="user.nationality"
          :show-icon="false"
        />
        <GenderSelect class="mt-2" v-model="user.gender" v-if="!uploadMode"/>
        <v-autocomplete
          v-model="selectedLineageNode"
          :items="lineageNodes"
          label="Select A Professor" 
          item-text="name"
          item-value="_id"
          :clearable="true"
          :loading="loadingTreeNodes"
          hide-no-data
          dense outlined
        ></v-autocomplete>
        <v-switch v-if="!uploadMode" label="Is a minor" v-model="user.isMinor"></v-switch>
        <v-switch v-if="!uploadMode" label="Not BJJ user" v-model="notBjjUser"></v-switch>
      </v-card-text>
    </v-card-text>
    <v-card-actions>
      <v-btn small :disabled="!selectedManagedAcademy || !user.email || !user.fName" color="primary" @click="addNewMember()">Add</v-btn>
      <v-btn elevation="0" small @click="clearAll()">Clear All</v-btn>
      <v-spacer></v-spacer>
      <v-btn elevation="0" small @click="goBack()">Back</v-btn>
    </v-card-actions>
    <v-card-actions v-if="uploadMode">
      <v-btn v-if="!uploadModeAlumni" elevation="0" :disabled="uploadingCsv || !selectedManagedAcademy" color="primary" block @click="triggerUpload()">
        <v-icon v-if="uploadingCsv" class="rotate">mdi-refresh</v-icon>
        View Members CSV Results And Confirm Upload
      </v-btn>
      <v-btn v-else elevation="0" :disabled="uploadingCsv || !selectedManagedAcademy" color="accent" block @click="triggerAlumniUpload()">
        <v-icon v-if="uploadingCsv" class="rotate">mdi-refresh</v-icon>
        Upload Almuni CSV (No Confirmation Needed)
      </v-btn>
      <input
        style="display: none"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        name="csv"
        @change="onCsvFileSelect"
        ref="csvFileUploadInput"
      />
    </v-card-actions>
    <v-card-actions v-show="false">
      <v-btn block elevation="0" @click="uploadCsv()" >
        AGAIN
      </v-btn>
    </v-card-actions>
    <v-dialog width="90%" v-model="uploadresultsDialog">
      <v-card outlined v-if="uploadResults">
        <v-card-title>Upload Results</v-card-title>
        <v-card-text>
          <v-switch v-model="confirmUpload" label="Confirm"></v-switch>
        </v-card-text>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon class="mr-1" color="error">mdi-close-circle-outline</v-icon> Errors ({{ uploadResults.errors.length }})
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="uploadResults.errors.length > 0">
              <v-list>
                <v-list-item v-for="(i) in uploadResults.errors" :key="i._id">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon size="20" class="mr-1">mdi-close-circle-outline</v-icon> <span class="font-weight-bold mr-1">Row: {{ i.row }}</span><span class="mr-1 ml-1">-</span>{{i.email}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="pl-2">
                      {{ i.error }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon class="mr-1" color="success">mdi-check-circle-outline</v-icon> Successess ({{ uploadResults.newMembers.length }})
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="uploadResults.newMembers.length > 0">
              <v-list>
                <v-list-item v-for="(i) in uploadResults.newMembers" :key="i._id">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon class="mr-1" size="20">mdi-check-circle-outline</v-icon> <span class="font-weight-bold mr-1">Row: {{ i.row }}</span><span class="mr-1 ml-1">-</span>{{i.email}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions v-if="!uploadModeAlumni">
           <v-btn elevation="0" :disabled="!uploadResults || uploadResults.newMembers.length == 0 || !confirmUpload" color="primary" block @click="addMembers()">
              <v-icon v-if="uploadingCsv" class="rotate">mdi-refresh</v-icon>
              Confirm Upload for {{ uploadResults.newMembers.length }} Results
            </v-btn>
        </v-card-actions>
        <v-card-actions v-else class="mt-2">
          <v-spacer></v-spacer>
          <v-btn elevation="0" small block @click="uploadresultsDialog=false">
            ACTION COMPLETED - CLOSE DIALOG
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import BjjlinkAdminService from "@/services/BjjlinkAdminService";
import LineageService from "@/services/LineageService";
import BeltsSelect from "@/components/inputs/BeltsSelect.vue";
import CountriesSelect from "@/components/inputs/CountriesSelect.vue";
import GenderSelect from "@/components/inputs/GenderSelect.vue";

export default {
  data() {
    return {
      user: null,
      academies: [],
      selectedManagedAcademy: null,
      lineageNodes: [],
      loadingTreeNodes: false,
      selectedLineageNode: null,
      uploadMode: false,
      uploadModeAlumni: false,
      loadingManagedAcademies: false,
      notBjjUser: false,
      uploadingCsv: false,
      selectedFile: null,
      uploadedCsvFile: null,
      uploadResults: null,
      uploadresultsDialog: null,
      confirmUpload: false,
      uploadPercentage: 0,
      addingMembers: false,
      onUploadProgress(progressEvent) {
        const percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        console.log('Percent: ',percent);
        /*
        if (percent >= 97)
          this.uploadPercentage = 97;//Stay on 97
        else
          this.uploadPercentage = percent;
        */
      }
    };
  },
  created(){
    this.initUser();
    this.getTreeNodes();
    this.getManagedAcademies();
  },
  components: {
    BeltsSelect,
    CountriesSelect,
    GenderSelect,
  },
  methods: {
    getUploadstemplate() {
      window.open(this.assets("templates/BJJLINK_UploadMembers_Template.csv"));
    },
    addMembers() {
      this.addingMembers = true;
      let data = this.uploadResults;
      if (data) {
        BjjlinkAdminService.submitNewMembers(this.selectedManagedAcademy, data)
          .then((results) => {
            this.showAlert("success", "Added new members: " + results.count);
            this.uploadresultsDialog = null;
            this.$refs.csvFileUploadInput.value = null;
            this.uploadResults = null;
            this.uploadMode = false;
            this.clearAll();
          }).catch(() => {
            this.showAlert("error", "Talk to Santi");
          });
      } else {
        this.showAlert("error", "Something went wrong");
      }
    },
    uploadCsv(persist) {
      const formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      if(this.selectedLineageNode)
        formData.append("masterId", this.selectedLineageNode);
      formData.append("password", this.user.password ? this.user.password : 'BJJLINK');
      formData.append("persist", persist);
      this.uploadingCsv = false;
      if (this.uploadModeAlumni) 
        formData.append("alumni", true);
      BjjlinkAdminService.uploadMembersCsv(this.selectedManagedAcademy, formData, this.onUploadProgress)
        .then((results) => {
          if(this.uploadModeAlumni){
            this.showAlert("success", results.message);
          }else{
            this.uploadresultsDialog = true;
            this.uploadResults = results;
          }
        }).catch((e) => {
          console.log(e);
          this.showAlert("error", "Talk to Santi");
        });

    },
    onCsvFileSelect(event) {
      try {
        if (event.target.files[0]) {
          this.uploadingCsv = true;
          this.selectedFile = event.target.files[0];
          this.uploadedCsvFile = null;
          this.uploadedFile = null;
          this.uploadCsv(false);
        } else {
          this.uploadingCsv = false;
          console.log("WTF!")
        }
      } catch (e) {
        console.log(e);
      }
    },
    triggerUpload() {
      this.$refs.csvFileUploadInput.click();
    },
    triggerAlumniUpload() {
      this.$refs.csvFileUploadInput.click();
    },
    clearAll(){
      this.initUser();
      this.selectedManagedAcademy = null;
      this.notBjjUser = false;
      this.showAlert("success", "Done");
    },
    getTreeNodes() {
      this.loadingTreeNodes = true;
      LineageService.treeNodes()
        .then(data => {
          if (data) {
            this.lineageNodes = data.nodes;
            this.loadingTreeNodes =false;
          }
        });
    },
    initUser(){
      const randomstring = Math.random().toString(36).slice(-8);
      this.user = {
        password: randomstring,
        belt: 'white',
        nationality: 'US',
        gender: 'male',
        isMinor: false,
      };
    },
    getManagedAcademies() {
      this.loadingManagedAcademies = true;
      BjjlinkAdminService.managedAcademies()
        .then((data) => {
          if(data && data.academies.length > 0){
            for(const a of data.academies)
              this.academies.push({
                value: a._id,
                text: a.name
              });
          }
          this.loadingManagedAcademies = false;
        });
    },
    addNewMember(){
      let data = {
        email: this.user.email.trim(),
        fName: this.user.fName.trim(),
        lName: this.user.lName.trim(),
        password: this.user.password,
        belt: this.user.belt,
        nationality: this.user.nationality,
        gender: this.user.gender,
        managedAcademy: this.selectedManagedAcademy,
        isMinor: this.user.isMinor,
        notBjjUser: this.notBjjUser,
        masterId: this.selectedLineageNode
      };
      BjjlinkAdminService.addManagedAcademyMember(data)
        .then(() => {
          this.showAlert("success", "Keep going!", 'Added: ' + data.fName + ' ' + data.lName);
          this.initUser();
        }).catch((e) => {
          if(e.response && e.response.data)
            this.showAlert("error", e.response.data.message);
          else
            this.showAlert("error", "Talk to Santi");
        });
    }
  },
};
</script>

<style scoped></style>
