import Vue from "vue";
import VueGtm from "@gtm-support/vue2-gtm";
import VueRouter from "vue-router";
import Home from "../views/HomePage.vue";
import Login from "@/views/ViewLogin";
import AppLogin from "@/views/AppLogin";
import Register from "@/views/ViewRegister";
import Jitsers from "@/views/ViewJitsers";
import Events from "@/views/ViewEvents";
import ViewMyEvents from "@/views/ViewMyEvents";
import ViewEvent from "@/views/ViewEvent";
import CreateEvent from "@/views/CreateEvent";
import ManageEvent from "@/views/ManageEvent";
import Academies from "@/views/ViewAcademies";
import Videos from "@/views/ViewVideos";
import ManageVideo from "@/views/ManageVideo";
import ViewVideo from "@/views/ViewVideo";
import News from "@/views/ViewNews";
import LineageTree from "@/views/ViewLineageTree";
import MapView from "@/views/ViewExplore";
import ViewNearby from "@/views/ViewNearby";
import Inbox from "@/views/ViewInbox";
import Notifications from "@/views/ViewNotifications";
import ViewPartners from "@/views/ViewPartners";
import ViewTournaments from "@/views/ViewTournaments";
import ViewPartner from "@/views/ViewPartner";
import ManagePartner from "@/views/ManagePartner";
import InfoExternal from "@/views/InfoExternal";
import ViewProfile from "@/views/ViewProfile";
import QR from "@/views/ViewQR";
import ViewProgressDetails from "@/views/ViewProgressDetails";
import ViewActiveSyllabus from "@/views/ViewActiveSyllabus";
import Points from "@/views/ViewPoints";
import ViewMyAcademy from "@/views/ViewMyAcademy";
import ViewUserImages from "@/views/ViewUserImages";
import Transaction from "@/views/ViewTransaction";
import Payments from "@/views/ViewPayments";
import Checkout from "@/views/ProcessCheckout";
import InfiniceptCheckout from "@/views/InfiniceptCheckout";
import CreditCardUpdate from "@/views/CreditCardUpdate";
import ViewAcademyClass from "@/views/ViewAcademyClass";
import ViewAcademyDocument from "@/views/ViewAcademyDocument";
import ViewAcademySurvey from "@/views/ViewAcademySurvey";
import ViewAcademyVisitorWaiver from "@/views/ViewAcademyVisitorWaiver";
import ViewMyTraining from "@/views/ViewMyTraining";
import EditProfile from "@/views/EditProfile";
import EditHistory from "@/views/EditHistory";
import Settings from "@/views/EditSettings";
import ViewAcademyReviews from "@/views/ViewAcademyReviews";
import ViewAcademyReview from "@/views/ViewAcademyReview";
import ViewAcademy from "@/views/ViewAcademy";
import AddAcademyUser from "@/views/AddAcademyUser";
import ManageAcademy from "@/views/ManageAcademy";
import ManageTimer from "@/views/ManageTimer";
import Timer from "@/views/ViewTimer";
import Help from "@/views/ViewHelp";
import ViewOurTerms from "@/views/ViewOurTerms";
import ViewPrivacyNotice from "@/views/ViewPrivacyNotice";
import ViewAgreement from "@/views/ViewAgreement";
import ViewAcademyMessage from "@/views/ViewAcademyMessage";
import SystemMessage from "@/views/SystemMessage";
import ResetPassword from "@/views/ResetPassword";
import ForgotPassword from "@/views/ForgotPassword";
import ViewPost from "@/views/ViewPost";
import UpdateEmail from "@/views/UpdateEmail";
import SuccessPage from "@/views/SuccessPage";
import QuickSearch from "@/views/QuickSearch";
import ElevateVideos from "@/views/ElevateVideos";
import ViewPosts from "@/views/ViewPosts";
import ViewWaivers from "@/views/ViewWaivers";
import ViewAffiliates from "@/views/ViewAffiliates";
import ViewAffiliateEntry from "@/views/ViewAffiliateEntry";

//Admins (these are temp)
import AddHelpAdmin from "@/views/admin/AddHelpAdmin";
import AdminHome from "@/views/admin/AdminHome";
import AddAcademyAdmin from "@/views/admin/AddAcademyAdmin";
import AdminLineageManager from "@/views/admin/LineageManager";
import ManagePartnersAdmin from "@/views/admin/ManagePartnersAdmin";
import AdminUsers from "@/views/admin/AdminUsers";
import InternalAggregates from "@/views/admin/InternalAggregates";
import ManagedAcademiesStats from "@/views/admin/ManagedAcademiesStats";
import ManageReleases from "@/views/admin/ManageReleases";
import ManageBanners from "@/views/admin/ManageBanners";
import ElevateEvents from "@/views/admin/ElevateEvents";
import VerifyBelts from "@/views/admin/VerifyBelts";
import AddManagedAcademyMember from "@/views/admin/AddManagedAcademyMember";
import AdminReports from "@/views/admin/AdminReports";

//Generic ones
import NotFound from "@/views/NotFound";
import NotFoundSecured from "@/views/NotFoundSecured";

Vue.use(VueRouter);

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next("/login");
  }
}
/*
function alreadyLoggedIn(to, from, next) {
  if (localStorage.token) {
    if (to.query && to.query.to == "elevate") {
      window.location = "/elevate";
    } else {
      next("/secure/home");
    }
  } else {
    next();
  }
}
*/
function checkAgreements(to, from, next) {
  next();
  /** 
   * We now put the message on the front door before registering
  if (localStorage.hasAgreements == "true") {
    next();
  } else {
    next("/secure/agreement");
  }
  */
}

const routes = [
  {
    path: "/",
    name: "root",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (localStorage.token) {
        next("/secure/home");
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/secure/home",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/home/:tabId",
    name: "hometab",
    component: Home,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/tablet", //Let admintablet pass through
  },
  {
    path: "/admin", //Let admin pass through
  },
  {
    path: "/info",
    name: "info",
    component: InfoExternal,
  },
  {
    path: "/qr",
    name: "qr",
    component: QR,
  },
  {
    path: "/info/:route",
    name: "infoexternal",
    component: InfoExternal,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/applogin/:token",
    name: "applogin",
    component: AppLogin,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/secure/agreement",
    name: "agreements",
    component: ViewAgreement,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/success",
    name: "success",
    component: SuccessPage,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  //Menu
  {
    path: "/secure/users",
    name: "users",
    component: Jitsers,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/events",
    name: "events",
    component: Events,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/elevatevideos",
    name: "elevatevideos",
    component: ElevateVideos,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/quicksearch",
    name: "quicksearch",
    component: QuickSearch,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/myevents",
    name: "myevents",
    component: ViewMyEvents,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academies",
    name: "academies",
    component: Academies,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/videos",
    name: "videos",
    component: Videos,
    props: true,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/videos/manage/:videoId",
    name: "managevideo",
    component: ManageVideo,
    props: true,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/videos/:videoId",
    name: "viewvideo",
    component: ViewVideo,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/videos/:videoId/:notificationId",
    name: "viewvideofromnotification",
    component: ViewVideo,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/news",
    name: "news",
    component: News,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/lineagetree",
    name: "lineagetree",
    component: LineageTree,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/map",
    name: "map",
    component: MapView,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/nearby",
    name: "nearby",
    component: ViewNearby,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/partners/manage/:partnerId",
    name: "managepartner",
    component: ManagePartner,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/partners/:partnerId",
    name: "viewpartner",
    component: ViewPartner,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/partners",
    name: "partners",
    component: ViewPartners,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/ibjjftournaments",
    name: "ibjjftournaments",
    component: ViewTournaments,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/ibjjftournaments/tournament/:tournamentId",
    name: "ibjjftournamentstourney",
    component: ViewTournaments,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/ibjjftournaments/tournament/:tournamentId/:notificationId",
    name: "ibjjftournamentstourneynotification",
    component: ViewTournaments,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/inbox",
    name: "inbox",
    component: Inbox,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/inbox/:threadId",
    name: "inboxthread",
    component: Inbox,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/notifications",
    name: "notifications",
    component: Notifications,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/profile/:userId",
    name: "viewprofile",
    component: ViewProfile,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/profile",
    name: "viewprofilesessionuser",
    component: ViewProfile,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/profile/:userId/:notificationId",
    name: "viewprofilefromnotification",
    component: ViewProfile,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/user/:userId",
    name: "userprofile",
    component: ViewProfile,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/users/progress/:instanceId",
    name: "viewprogressdetails",
    component: ViewProgressDetails,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/users/progress/:instanceId/:notificationId",
    name: "viewprogressdetailsnotification",
    component: ViewProgressDetails,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/users/programs",
    name: "viewactivesyllabus",
    component: ViewActiveSyllabus,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/points",
    name: "points",
    component: Points,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/waivers",
    name: "waivers",
    component: ViewWaivers,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/myacademy/tabs/:tabId",
    name: "myacademytab",
    component: ViewMyAcademy,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/profile/images/:userId",
    name: "viewuserimages",
    component: ViewUserImages,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/payments/checkout/:transactionId",
    name: "checkout",
    component: Checkout,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/transactions/checkout/inifni",
    name: "inifnicept-checkout",
    component: InfiniceptCheckout,
    props: true,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/payments/checkout/:transactionId/:notificationId",
    name: "checkoutfromnotification",
    component: Checkout,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/payments/checkout/cc/update/:transactionId",
    name: "ccupdate",
    component: CreditCardUpdate,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/payments/checkout/cc/update/member/:memberId",
    name: "ccupdatemember",
    component: CreditCardUpdate,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/payments/checkout/cc/update/:transactionId/:notificationId",
    name: "ccupdatefromnotification",
    component: CreditCardUpdate,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/payments/transaction/:transactionId",
    name: "transaction",
    component: Transaction,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/payments/:transactionId",
    name: "paymentsupdatecard",
    component: Payments,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/payments",
    name: "payments",
    component: Payments,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/academy/classes/:academyClassId/:notificationId",
    name: "viewacademyclassnotification",
    component: ViewAcademyClass,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/classes/:academyClassId",
    name: "viewacademyclass",
    component: ViewAcademyClass,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/documents/:academyDocumentId",
    name: "viewacademydocument",
    component: ViewAcademyDocument,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/surveys/:academyDocumentId/:notificationId",
    name: "viewacademysurvey",
    component: ViewAcademySurvey,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/surveys/:academyDocumentId",
    name: "viewacademysurveynotification",
    component: ViewAcademySurvey,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/documents/visitor/:academyId",
    name: "viewacademyvisitorwaiver",
    component: ViewAcademyVisitorWaiver,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/documents/:academyDocumentId/:notificationId",
    name: "viewacademydocumentnotification",
    component: ViewAcademyDocument,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/events/:eventId",
    name: "viewevent",
    component: ViewEvent,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/events/create/event",
    name: "createevent",
    component: CreateEvent,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/events/manage/:eventId",
    name: "manageevent",
    component: ManageEvent,
    props: true,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/events/manage/:eventId/:academyId",
    name: "createeventacademy",
    component: ManageEvent,
    props: true,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/journal",
    name: "editjournal",
    component: ViewMyTraining,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/training/:tabId",
    name: "viewmytrainingtab",
    component: ViewMyTraining,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/editprofile",
    name: "editprofile",
    component: EditProfile,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/history",
    name: "edithistory",
    component: EditHistory,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/settings",
    name: "settings",
    component: Settings,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/help",
    name: "helpsecure",
    component: Help,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/add/new",
    name: "addacademy",
    component: AddAcademyUser,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/:academyId",
    name: "viewacademy",
    component: ViewAcademy,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academy/:academyId/:notificationId",
    name: "viewacademyfromnotification",
    component: ViewAcademy,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/academies/manage/:academyId",
    name: "manageacademy",
    component: ManageAcademy,
    props: true,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/reviews/:academyId",
    name: "academyreviews",
    component: ViewAcademyReviews,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/review/:reviewId",
    name: "academyreview",
    component: ViewAcademyReview,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/termsandconditions",
    name: "viewourterms",
    component: ViewOurTerms,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/privacynotice",
    name: "viewprivacy",
    component: ViewPrivacyNotice,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/timers/manage/:timerId",
    name: "managetimer",
    component: ManageTimer,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/timers/start/:timerId",
    name: "viewtimer",
    component: Timer,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/admin/home",
    name: "adminhome",
    component: AdminHome,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/admin/internalaggregates",
    name: "internalaggregates",
    component: InternalAggregates,
  },
  {
    path: "/secure/admin/managedacademiesstats",
    name: "managedacademiesstats",
    component: ManagedAcademiesStats,
  },
  {
    path: "/secure/admin/managereleases",
    name: "managereleases",
    component: ManageReleases,
  },
  {
    path: "/secure/admin/managebanners",
    name: "managebanners",
    component: ManageBanners,
  },
  {
    path: "/secure/admin/elevateevents",
    name: "elevateevents",
    component: ElevateEvents,
  },
  {
    path: "/secure/admin/verifybelts",
    name: "verifybelts",
    component: VerifyBelts,
  },
  {
    path: "/secure/admin/users",
    name: "adminusers",
    component: AdminUsers,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/admin/addmanagedacademymembers",
    name: "addmanagedacademymembers",
    component: AddManagedAcademyMember,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/admin-reports",
    name: "viewadminreports",
    component: AdminReports,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/admin/lineagemanager",
    name: "adminlineagemanager",
    component: AdminLineageManager,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/admin/managepartners",
    name: "managepartnersadmin",
    component: ManagePartnersAdmin,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/admin/addhelp",
    name: "addhelpadmin",
    component: AddHelpAdmin,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/admin/admin_add_academy",
    name: "admin_add_academy",
    component: AddAcademyAdmin,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
    },
  },
  {
    path: "/secure/messages/academy/:messageId",
    name: "academymessage",
    component: ViewAcademyMessage,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/systemmessages/:notificationId",
    name: "viewsystemmessage",
    component: SystemMessage,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/reset-password/:token",
    name: "resetpassword",
    component: ResetPassword,
  },
  {
    path: "/forgot-password",
    name: "forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/secure/posts/:postId",
    name: "viewpost",
    component: ViewPost,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/posts/feed/:feed",
    name: "viewposts",
    component: ViewPosts,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/posts/:postId/:notificationId",
    name: "viewpostfromnotification",
    component: ViewPost,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/affiliates",
    name: "affiliates",
    component: ViewAffiliates,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/secure/affiliates/:id",
    name: "viewaffiliate",
    component: ViewAffiliateEntry,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "/update-email/:token",
    name: "updateemail",
    component: UpdateEmail,
  },
  {
    path: "/secure/notfound",
    name: "notfoundsecured",
    component: NotFoundSecured,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
  {
    path: "*",
    name: "notfound",
    component: NotFound,
    beforeEnter: (to, from, next) => {
      isLoggedIn(to, from, next);
      checkAgreements(to, from, next);
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

Vue.use(VueGtm, {
  id: "GTM-TN5PKR4D", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  //queryParams: {
  // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //  gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
  //  gtm_preview: "env-4",
  //  gtm_cookies_win: "x",
  //},
  defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  //nonce: "2726c7f26c", // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  //loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

export default router;
